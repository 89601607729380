<template>
  <main v-if="!loading" class="page">
    <header class="page__header p-2">
      <img :src="require('@/assets/images/pages/logo_piclink.png')" alt="" aria-hidden="true" class="d-block" style="height: 100%; width: auto" />
    </header>
    <div class="page__content py-4">
      <div class="mx-0 pb-3 row border-bottom align-items-center">
        <div class="col">
          <h1 class="font-weight-bold">Progetti disponibili</h1>
        </div>
      </div>
      <div style="overflow-y: auto">
        <router-link v-for="project in projects" :to="{ name: 'scan-resource', params: { projectId: project.id } }" :key="project.id">
          <div class="row mx-0 py-4 border-bottom">
            <div class="col-3">
              <b-img thumbnail class="border-0 p-0" :src="project.thumbSrc" :alt="project.name" />
            </div>
            <div class="col pl-0">
              <p class="h3 text-dark mb-0">{{ project.title }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

export default {
  data () {
    return {
      projects: [],
      loading: true,
    };
  },

  mounted () {
    this.projects = this.$storage.get('projects') || [];

    if (this.projects.length === 1) {
      const project = this.projects[0];
      this.$router.replace({ name: 'scan-resource', params: { projectId: project.id } });

      return;
    }

    this.loading = false;
  },
};

</script>
